import {Box, Meter} from "grommet";
import {useEffect, useState} from "react";

const colors = ["#9c46fc", "#3259f0", "#43d7fc", "#6deaa2", "#fbea4c", "#ee7635", "orange"]
export const Pie = ({
                        label,
                        values,
                        keyProperty,
                        primaryValueProperty,
                        valueProperties,
                        onClick,
                        onHover,
                        lockedValue
                    }) => {
    const [highlightedItem, setHighlightedItem] = useState(null);

    let pieValues = values.map((value, i) => {
        return {
            value: value[primaryValueProperty],
            color: value[keyProperty] === highlightedItem ? "#eb58f7" : colors[i],
            label: value[keyProperty],
            onHover: (a) => {
                if (a) {
                    !lockedValue && setHighlightedItem(value[keyProperty]);
                    !lockedValue && onHover && onHover(value);
                } else {
                    !lockedValue && setHighlightedItem(null);
                    !lockedValue && onHover && onHover(null);
                }
            },
            onClick: (a) => {
                onClick && onClick(value);
            }
        }
    });

    useEffect(() => {
        setHighlightedItem(lockedValue?.key);
    }, [lockedValue]);

    return (
        <Box pad={"medium"} align={"center"}>
            <Box>
                <Box margin={{bottom: "1em"}}><b>{label}</b></Box>
                <Meter
                    type="pie"
                    background="light-2"
                    size="300px"
                    values={pieValues}
                />
            </Box>
            <Box style={{minHeight: "200px"}}>
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Values</th>
                    </tr>
                    </thead>
                    <tbody>
                    {values.map((value, i) => {
                        return (
                            <tr key={value[keyProperty]}
                                onMouseOver={() => {
                                    !lockedValue && setHighlightedItem(value[keyProperty])
                                    !lockedValue && onHover && onHover(value);
                                }}
                                onMouseOut={() => {
                                    !lockedValue && setHighlightedItem(null)
                                    !lockedValue && onHover && onHover(null);
                                }}
                                onClick={onClick?.bind(this, value)}
                                style={{
                                    backgroundColor: highlightedItem === value[keyProperty] ? "#eb58f7" : "transparent",
                                    cursor: onClick ? "pointer" : "inherit"
                                }}>
                                <td><Box background={colors[i]} width={"20px"} height={"20px"}/></td>
                                <td>
                                    {value[keyProperty]}
                                </td>
                                {valueProperties.map(vp => {
                                    return (
                                        <td key={vp} align={"right"}>
                                            {value[vp]}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </Box>
        </Box>
    )
}