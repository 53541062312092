import React from "react";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "./authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
    const {instance} = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch((e) => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };
    return (
        <button
            style={{padding: "1em 2em 1em 2em"}}
            onClick={() => handleLogin("redirect")}>Sign in to time reporting sync</button>
    );
};