import React, {useContext, useEffect, useState} from "react";
import {rootUrl, UserContext} from "./App";
import Moment from "moment-timezone";
import {ArrowCounterclockwise, CaretLeft, CaretRight} from "react-bootstrap-icons";
import {Box, Button} from "grommet";
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';

export const BillableMonthlySummary = (props) => {
    const user = useContext(UserContext);
    const [summary, setSummary] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(null)

    useEffect(() => {
        setCurrentMonth(new Moment().subtract(1, "month"));
    }, []);

    useEffect(() => {
        loadSummary();
    }, [user, currentMonth]);

    const loadSummary = async () => {
        if (user?.token) {
            fetch(`${rootUrl}/api/get_monthly_summary?month=${currentMonth?.toISOString()}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
                .then(res => res.json())
                .then(summary => {
                    setSummary(summary);
                });
        }
    }
    const saveToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(summary);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `billingsummary-${currentMonth.format("YYYYMM")}.xlsx`);
    }
    const refreshMonth = async () => {
        if (user?.token) {
            setSummary([])
            fetch(`${rootUrl}/api/refresh_period?period=${currentMonth?.toISOString()}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
                .then(res => res.json())
                .then(summary => {
                    loadSummary();
                });
        }
    }
    let sumHours = 0, sumAmount = 0;
    let currencyFormat = Intl.NumberFormat('sv-SE', {style: 'currency', currency: "SEK", maximumFractionDigits: 0});
    let numberFormat = Intl.NumberFormat('sv-SE', {style: 'decimal', useGrouping: false});

    return (
        <div>
            <Box direction={"row"} align={"center"} pad={"small"} justify={"between"}>
                <Box direction={"row"} align={"center"}>
                    <Button onClick={() => setCurrentMonth(new Moment(currentMonth.subtract(1, "month")))}
                            icon={<CaretLeft
                                size={"18px"}/>} label={"Previous"}/>
                    <div style={{margin: "0 1em 0 1em", fontSize: "25px"}}>{currentMonth?.format("YYYY-MM")}</div>
                    <Button onClick={() => setCurrentMonth(new Moment(currentMonth.add(1, "month")))} icon={<CaretRight
                        size={"18px"}/>} label={"Next"}/>
                </Box>
                <Box direction={"row"}>
                    <Button style={{marginLeft: "1em"}} onClick={() => refreshMonth()}
                            label={"Reload period from Harvest"}
                            icon={<ArrowCounterclockwise size={"22px"}/>}/>
                    <Button style={{marginLeft: "1em"}} onClick={() => saveToExcel()} label={"Export to Excel"}
                            icon={<ArrowCounterclockwise size={"22px"}/>}/>
                </Box>
            </Box>
            {summary && summary.length > 0 &&
                <table width={"100%"}>
                    <thead>
                    <tr>
                        <th>Client</th>
                        <th>Fortnox ID</th>
                        <th>Project</th>
                        <th>Task</th>
                        <th>Hourly rate</th>
                        <th>Hours</th>
                        <th>Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {summary.map((s, i) => {
                        sumHours += Number(s.hours);
                        sumAmount += Number(s.amount);
                        return (
                            <tr key={i}>
                                <td>{s.clientname}</td>
                                <td>{s.fortnox_id}</td>
                                <td>{s.projectname}</td>
                                <td>{s.taskname}</td>
                                <td align={"right"}>{numberFormat.format(s.hourlyrate)}</td>
                                <td align={"right"}>{numberFormat.format(s.hours)}</td>
                                <td align={"right"}>{numberFormat.format(s.amount)}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <th colSpan={"5"}>Sum</th>
                        <th>{numberFormat.format(sumHours)}</th>
                        <th>{numberFormat.format(sumAmount)}</th>
                    </tr>
                    </tbody>
                </table>
            }
            {!summary || summary.length === 0 && <div style={{display: "flex", alignItems: "center"}}>
                <div className="spinner-border" role="status">
                </div>
                <div style={{padding: "1em"}}>
                    Loading time entries
                </div>
            </div>}

        </div>
    )
}