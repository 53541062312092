import Moment from "moment/moment";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "./App";
import {ArrowClockwise, CloudDownload, CloudUpload} from "react-bootstrap-icons";
import {
    exportWorklogsToHarvest,
    getHarvestClients,
    getUnApprovedWorklogs,
    importTempoWorklogs,
    updateBillable
} from "./api";
import {Box, Button, Tip} from "grommet";


export const BillableReview = () => {
    const [worklogs, setWorklogs] = useState(null)
    const [billable, setBillable] = useState({})
    const [harvestClients, setHarvestClients] = useState({});
    const user = useContext(UserContext);
    useEffect(() => {
        loadWorklogs()
    }, [user?.token]);

    const loadWorklogs = async () => {
        if (user?.token) {
            getHarvestClients(user.token)
                .then(clients => {
                    setHarvestClients(clients);
                    getUnApprovedWorklogs(user.token)
                        .then(worklogs => {
                            setWorklogs(worklogs);
                            let billables = Object.assign({}, billable);
                            worklogs.map(worklog => {
                                billables[worklog.worklog_id] = worklog.billable
                            })
                            setBillable(billables);
                        });
                });
        }
    }
    const onBillableChange = (worklogId, isBillable) => {
        let apprs = Object.assign({}, billable);
        apprs[worklogId] = isBillable;
        setBillable(apprs);
    }

    const clearBillable = () => {
        setBillable({});
    }

    const submitBillable = () => {
        setWorklogs(null)
        updateBillable(user.token, billable)
            .then(res => {
                loadWorklogs();
            })
    }
    const importFromTempo = () => {
        setWorklogs();
        importTempoWorklogs(user.token)
            .then(() => {
                loadWorklogs();
            })
    }

    const submitToHarvest = () => {
        setWorklogs();
        exportWorklogsToHarvest(user.token)
            .then((res) => {
                console.log("res", res)
                loadWorklogs()
            })
    }

    const multiSelect = (filter, value) => {
        let newBillable = Object.assign({}, billable);
        worklogs.filter(filter).forEach(w => {
            newBillable[w.worklog_id] = value;
        });
        setBillable(newBillable);
    }

    return (
        <div>
            <Box direction={"row"} justify={"end"} pad={"medium"} gap={"small"}>
                <Button pad={{horizontal: "small", vertical: "xsmall"}} onClick={() => importFromTempo()}
                        label={"Import from Tempo"} icon={<CloudDownload
                    size={"14px"}/>}/>
                <Button onClick={() => loadWorklogs()} icon={<ArrowClockwise size={"14px"}/>} label={"Reload"}/>
                <Button onClick={() => submitToHarvest()} icon={<CloudUpload/>} label={"To Harvest"}/>
            </Box>
            <div style={{
                width: "100%",
                minHeight: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <table width={"100%"}>
                    <thead>
                    <tr>
                        <th>WorklogId</th>
                        <th>Summary</th>
                        {/*<th>Worklog description</th>*/}
                        <th>Date</th>
                        <th>Hours</th>
                        <th>Reporter</th>
                        <th>Ticket</th>
                        <th>Root ticket</th>
                        <th>Client</th>
                        <th>In Harvest</th>
                        <th>Approve</th>
                    </tr>
                    </thead>
                    <tbody>
                    {worklogs && worklogs.map(w => {
                        let rootKey = w.root_key !== w.key ? w.root_key : "";
                        let rootLink = w.root_key !== w.key ? w.json.ticket.parent.url : "";
                        let rootSummary = w.root_key !== w.key ? w.root_summary : "";
                        let description = w.json.description;
                        if (description?.length > 50) {
                            description = description.substring(0, 50);
                        }

                        let harvestClient = harvestClients[w.harvestclientid];
                        return (
                            <tr key={w.worklog_id}>
                                <td>{w.worklog_id}</td>
                                <td>{w.summary}<br/>{description}</td>
                                {/*<td></td>*/}
                                <td>{new Moment(w.start_date).format("YYYY-MM-DD")}</td>
                                <td align="right">{w.hours}</td>
                                <td>{w.json.user.name}</td>
                                <td>
                                    <Tip plain={true} content={
                                        <Box style={{maxWidth: "300px"}} round={"small"} background={"white"}
                                             pad={"small"}>
                                            <b>Summary</b>
                                            {w.summary}
                                            <p/>
                                            <b>Bulk select</b>
                                            <Box direction={"row"} justify={"between"} pad={"small"} gap={"small"}>
                                                <Button label={"Yes"}
                                                        onClick={() => multiSelect(wl => w.key === wl.key, true)}/>
                                                <Button label={"No"}
                                                        onClick={() => multiSelect(wl => w.key === wl.key, false)}/>
                                                <Button label={"Skip"}
                                                        onClick={() => multiSelect(wl => w.key === wl.key, "skip")}/>
                                            </Box>
                                        </Box>
                                    }>
                                        <a target="jira" href={w.json.ticket.link}>{w.key}</a>
                                    </Tip>
                                </td>
                                <td>
                                    <Tip plain={true} content={
                                        <Box style={{maxWidth: "300px"}} round={"small"} background={"white"}
                                             pad={"small"}>
                                            <b>Summary</b>
                                            {rootSummary}
                                            <p/>
                                            <b>Bulk select</b>
                                            <Box direction={"row"} justify={"between"} pad={"small"} gap={"small"}>
                                                <Button label={"Yes"}
                                                        onClick={() => multiSelect(w => w.root_key === rootKey, true)}/>
                                                <Button label={"No"}
                                                        onClick={() => multiSelect(w => w.root_key === rootKey, false)}/>
                                                <Button label={"Skip"}
                                                        onClick={() => multiSelect(w => w.root_key === rootKey, "skip")}/>
                                            </Box>
                                        </Box>
                                    }>
                                        <a target="jira" href={rootLink}>{rootKey}</a>
                                    </Tip>
                                </td>
                                <td>
                                    <Tip plain={true} content={
                                        <Box style={{maxWidth: "300px"}} round={"small"} background={"white"}
                                             pad={"small"}>
                                            <b>Bulk select</b>
                                            <Box direction={"row"} justify={"between"} pad={"small"} gap={"small"}>
                                                <Button label={"Yes"}
                                                        onClick={() => multiSelect(wl => w.harvestclientid === wl.harvestclientid, true)}/>
                                                <Button label={"No"}
                                                        onClick={() => multiSelect(wl => w.harvestclientid === wl.harvestclientid, false)}/>
                                                <Button label={"Skip"}
                                                        onClick={() => multiSelect(wl => w.harvestclientid === wl.harvestclientid, "skip")}/>
                                            </Box>
                                        </Box>
                                    }>
                                        {harvestClient?.name}
                                    </Tip>
                                </td>
                                <td>{!!w.harvest_time_entry_id ? "Yes" : ""}</td>
                                <td>
                                    <div style={{display: "flex", direction: "row"}}>
                                        <div>
                                            <input checked={billable[w.worklog_id] === true}
                                                   onChange={() => harvestClient && onBillableChange(w.worklog_id, true)}
                                                   type={"radio"}
                                                   name={"approved" + w.worklog_id} value={true}/>Yes
                                        </div>
                                        <div>
                                            <input checked={billable[w.worklog_id] === false}
                                                   onChange={() => harvestClient && onBillableChange(w.worklog_id, false)}
                                                   type={"radio"} name={"approved" + w.worklog_id} value={false}/>No
                                        </div>
                                        <div>
                                            <input checked={billable[w.worklog_id] === "skip"}
                                                   onChange={onBillableChange.bind(this, w.worklog_id, "skip")}
                                                   type={"radio"} name={"approved" + w.worklog_id} value={"skip"}/>Skip
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={"10"}>
                            <Box direction={"row"} justify={"end"} pad={"medium"} gap={"small"}>
                                <Button secondary onClick={clearBillable} label={"Clear"}/>
                                <Button primary onClick={submitBillable} label={"Save"}/>
                            </Box>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {!worklogs && <div style={{display: "flex", alignItems: "center"}}>
                    <div className="spinner-border" role="status">
                    </div>
                    <div style={{padding: "1em"}}>
                        Loading worklogs
                    </div>
                </div>}
            </div>
        </div>
    )
}