import React, {useContext, useEffect, useState} from "react";
import {getEngineeringTimeDistribution, getTicketSummary} from "./api";
import {UserContext} from "./App";
import {Box, Button} from "grommet";
import Moment from "moment-timezone";
import {CaretLeft, CaretRight} from "react-bootstrap-icons";
import {Pie} from "./components/Pie";


export const EngineeringTimeDistribution = () => {
    const user = useContext(UserContext);
    const [distribution, setDistribution] = useState(null);
    const [platformDistribution, setPlatformDistribution] = useState(null);
    const [worktypeDistribution, setWorktypeDistribution] = useState(null);
    const [platformFilter, setPlatformFilter] = useState(null);
    const [worktypeFilter, setWorktypeFilter] = useState(null);
    const [hoursToActivate, setHoursToActivate] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(new Moment())
    const [ticketSummary, setTicketSummary] = useState(null);
    const [filter, setFilter] = useState();

    useEffect(() => {
        if (user?.token) {
            getEngineeringTimeDistribution(user.token, currentMonth?.toISOString())
                .then(res => {
                    setHoursToActivate(res.filter(d => d.worktype==="Roadmap").map(d => d.hours).reduce((a,b) => a+b));
                    setDistribution(res)
                    setPlatformDistribution(buildDistribution(res, "platform"));
                    setWorktypeDistribution(buildDistribution(res, "worktype"));
                })
        }
    }, [user, currentMonth]);

    useEffect(() => {
        if (user?.token && filter) {
            getTicketSummary(user.token, currentMonth?.toISOString(), filter.platform, filter.worktype)
                .then(res => {
                    setTicketSummary(res);
                })
        }
    }, [filter])

    useEffect(() => {
        setWorktypeDistribution(buildDistribution(distribution, "worktype", (value) => !platformFilter || value.platform === platformFilter))
    }, [platformFilter]);

    useEffect(() => {
        setPlatformDistribution(buildDistribution(distribution, "platform", (value) => !worktypeFilter || value.worktype === worktypeFilter))
    }, [worktypeFilter]);

    const buildDistribution = (distribution, pivotAttribute, filterFunction) => {
        if (!distribution) {
            return null;
        }
        let filteredDistribution = distribution.slice();
        if (filterFunction) {
            filteredDistribution = distribution.slice().filter(filterFunction);
        }
        let totalSum = filteredDistribution.reduce((a, b) => {
            return {hours: a.hours + b.hours};
        }, {hours: 0}).hours;
        let names = filteredDistribution.sort((a, b) => b.hours - a.hours).map(d => d[pivotAttribute]).filter((value, index, array) => array.indexOf(value) === index);
        let pivotDistribution = []
        names.map(name => {
            let hours = filteredDistribution.filter(d => d[pivotAttribute] === name).map(d => d.hours).reduce((a, b) => a + b, 0);
            pivotDistribution.push({
                key: name || "Internal/undefined",
                number: hours,
                hours: Intl.NumberFormat("sv-SE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }).format(hours) + " h",
                percentage: Intl.NumberFormat("sv-SE", {
                    style: "percent",
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2
                }).format(hours / totalSum)
            })
        })

        return pivotDistribution;
    }

    let platform, worktype = null;
    let platformSum = 0;

    return (
        <Box pad={"small"}>
            <Box direction={"row"} gap={"small"}>
                <Box>
                    <div style={{display: "flex", flexDirection: "row", margin: "1em", alignItems: "center"}}>
                        <Button onClick={() => setCurrentMonth(new Moment(currentMonth.subtract(1, "month")))}
                                icon={<CaretLeft
                                    size={"22px"}/>} label={"Previous"}/>
                        <div style={{margin: "0 1em 0 1em", fontSize: "25px"}}>{currentMonth?.format("YYYY-MM")}</div>
                        <Button onClick={() => setCurrentMonth(new Moment(currentMonth.add(1, "month")))}
                                icon={<CaretRight size={"22px"}/>} label={"Next"}/>
                    </div>
                    {distribution &&
                        <Box direction={"row"} gap={"medium"}>
                            <Box>
                                <table className={"clickable-table"}>
                                    <thead>
                                    <tr>
                                        <th>Platform</th>
                                        <th>Worktype</th>
                                        <th>Hours</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {distribution.map((r, i) => {
                                        let platformTitle = r.platform;
                                        if (platform !== platformTitle) {
                                            platform = platformTitle;
                                        } else {
                                            platformTitle = ""
                                        }
                                        if (platformTitle === null) {
                                            platformTitle = <i style={{color: "#ab3131"}}>Internal/Undefined</i>
                                        }
                                        return (
                                            <tr key={r.platform + r.worktype}
                                                onClick={() => setFilter({platform: r.platform, worktype: r.worktype})}>
                                                <td style={{paddingRight: "2em"}}>{platformTitle}</td>
                                                <td style={{paddingRight: "4em"}}>{r.worktype ||
                                                    <i style={{color: "#ab3131"}}>Internal/Undefined</i>}</td>
                                                <td align="right">{Intl.NumberFormat("sv-SE", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 2,
                                                }).format(r.hours) + " h"}</td>
                                            </tr>
                                        );
                                    })
                                    }
                                    {/*<tr style={{backgroundColor: "white"}}><td>&nbsp;</td></tr>*/}
                                    <tr style={{borderTop: "1px solid grey", backgroundColor: "white"}}>
                                        <td>Hours to activate</td>
                                        <td style={{color: "#888"}}>(sum of Roadmap hours)</td>
                                        <td align={"right"}>{hoursToActivate} h</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    }
                </Box>
                {platformDistribution &&
                    <Pie primaryValueProperty={"number"} valueProperties={["hours", "percentage"]}
                         label={"By platform"}
                         onHover={(value) => {
                             setPlatformFilter(value?.key)
                         }}
                         keyProperty={"key"}
                         values={platformDistribution}/>
                }
                {worktypeDistribution &&
                    <Pie primaryValueProperty={"number"} valueProperties={["hours", "percentage"]}
                         label={"By work type"}
                         keyProperty={"key"}
                         onHover={value => setWorktypeFilter(value?.key)}
                         values={worktypeDistribution}/>
                }

            </Box>
            <Box>
                {ticketSummary &&
                    <table>
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Summary</th>
                            <th>Platform</th>
                            <th>Worktype</th>
                            <th align={"right"}>Hours</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ticketSummary.map((r, i) => {
                            return (
                                <tr key={r.key + r.platform}>
                                    <td style={{paddingRight: "2em"}}><a target="_jira"
                                                                         href={"https://norce.atlassian.net/browse/" + r.key}>{r.key}</a>
                                    </td>
                                    <td style={{paddingRight: "4em"}}>{r.summary}</td>
                                    <td style={{paddingRight: "4em"}}>{r.platform || <i style={{color: "#ab3131"}}>Undefined</i>}</td>
                                    <td style={{paddingRight: "4em"}}>{r.worktype || <i style={{color: "#ab3131"}}>Undefined</i>}</td>
                                    <td align="right">{Intl.NumberFormat("sv-SE", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                    }).format(r.hours) + " h"}</td>
                                </tr>
                            );
                        })
                        }
                        </tbody>
                    </table>
                }
            </Box>
        </Box>
    )
}