import React, {createContext, useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from "./authConfig";
import {PageLayout} from "./PageLayout";
import {BillableReview} from "./BillableReview";
import {BillableMonthlySummary} from "./BillableMonthlySummary";
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import {EngineeringTimeDistribution} from "./EngineeringTimeDistribution";


export const UserContext = createContext(null);
export const CurrentTabContext = createContext("review");


export let rootUrl = "https://timereporting-integration.azurewebsites.net";
if (document.location.href.indexOf("localhost") > 0) {
    rootUrl = "http://localhost:7071";
}

export const App = (props) => {
    const [token, setToken] = useState();
    const isAuthenticated = useIsAuthenticated();
    const {instance} = useMsal();
    const [user, setUser] = useState(null);
    const [componentName, setComponentName] = useState("review")

    useEffect(() => {
        const account = instance.getAllAccounts()[0];
        const accessTokenRequest = {
            scopes: loginRequest.scopes,
            account: account,
        };
        if (isAuthenticated === true) {
            instance.acquireTokenSilent(accessTokenRequest)
                .then(t => {
                    setUser({
                        token: t.idToken,
                        name: account.name
                    })
                })
                .catch(e => {
                    console.error(e);
                    instance.loginRedirect(loginRequest).catch((e) => {
                        console.log(e);
                    });
                    setToken(null)
                });
        }
    }, [isAuthenticated]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <PageLayout/>,
            children: [
                {
                    path: "/",
                    element: <BillableReview/>
                },
                {
                    path: "billablesummary",
                    element: <BillableMonthlySummary/>,
                },
                {
                    path: "engineering",
                    element: <EngineeringTimeDistribution/>
                }
            ]
        },
    ], {
        basename: "/",
    });

    return (
        <UserContext.Provider value={user}>
            <RouterProvider router={router}/>
        </UserContext.Provider>
    );
}

