import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import {App} from './App';

import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from './authConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grommet} from "grommet";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = {
    global: {
        font: {
            size: "15px",
            family: "\"Space Grotesk\""
        },
        colors: {
            brand: '#eb58f7'
        },
    },
    button: {
        size: {
            small: {
                border: {
                    radius: "80px"
                }
            },
            medium: {
                border: {
                    radius: "80px"
                }
            },
            large: {
                border: {
                    radius: "80px"
                }
            }
        },
        primary: {
            extend: `color: white;`
        },
    },
    text: {
        xsmall: {
            size: '12px',
            height: '18px',
        },
        small: {
            size: '14px',
            height: '20px',
        },
        medium: {
            size: '16px',
            height: '24px',
        },
        large: {
            size: '18px',
            height: '28px',
        },
        xlarge: {
            size: '20px',
            height: '32px',
        },
        xxlarge: {
            size: '22px',
            height: '40px',
        },
    }
}

root.render(
    <MsalProvider instance={msalInstance}>
        <React.StrictMode>
            <Grommet theme={theme} full>
                <App/>
            </Grommet>
        </React.StrictMode>
    </MsalProvider>
)
