// import_tempo_worklogs
import {rootUrl} from "./App";

export const getHarvestClients = async (token) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/get_harvest_clients`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(clients => {
                resolve(clients)
            })
            .catch(e => {
                reject(e)
            });
    })
}

export const getEngineeringTimeDistribution = async (token, month, facets, filter) => {
    facets = facets ?? "platform,worktype";
    filter = filter ?? "";
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/engineering_distribution?month=${month}&facets=${facets}&filter=${filter}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(clients => {
                resolve(clients)
            })
            .catch(e => {
                reject(e)
            });
    })
}

export const getTicketSummary = async (token, month, platform, worktype) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/get_tickets_summary?month=${month}&platform=${platform}&worktype=${worktype}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(clients => {
                resolve(clients)
            })
            .catch(e => {
                reject(e)
            });
    })
}

export const getUnApprovedWorklogs = async (token) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/get_unapproved_worklogs`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(worklogs => {
                resolve(worklogs);
            })
            .catch(e => reject(e));
    });
}

export const updateBillable = async (token, billable) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/worklog_setbillable`, {
            method: "PUT",
            body: JSON.stringify(billable),
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status < 200 || res.status >= 300) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(response => resolve(response))
            .catch(e => reject(e));
    })
}

export const importTempoWorklogs = async (token) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/import_tempo_worklogs`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(worklogs => {
                resolve(worklogs);
            })
            .catch(e => reject(e));
    });
}
export const exportWorklogsToHarvest = async (token) => {
    return new Promise((resolve, reject) => {
        fetch(`${rootUrl}/api/export_worklogs_to_harvest`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    reject({status: res.status, message: res.statusMessage, statusText: res.statusText});
                    return;
                }
                return res.json();
            })
            .then(worklogs => {
                resolve(worklogs);
            })
            .catch(e => reject(e));
    })
}